/* colors */
/* font-family */
/* easing */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, input, textarea, button, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font-weight: inherit;
  font-style: normal;
  vertical-align: baseline;
}

body {
  -webkit-text-size-adjust: 100%;
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ul, ol {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select {
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

img {
  -ms-interpolation-mode: bicubic;
}

::selection {
  background: #000;
  color: #fff;
}

::-moz-selection {
  background: #000;
  color: #fff;
}

* {
  box-sizing: border-box;
}

html {
  position: relative;
  overflow-x: hidden;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  line-height: 2;
  letter-spacing: 0;
  color: #333;
  background-color: #fcfbf5;
  overflow-x: hidden;
}

#body-wrap {
  overflow: hidden;
  position: relative;
}

#body-wrap #body-inner {
  display: block;
  opacity: 0;
  position: relative;
  left: 100px;
  transition: left 1.5s;
}

#body-wrap:after {
  content: '';
  opacity: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: #000;
  transform: translateX(-101%);
}

#body-wrap.on #body-inner {
  left: 0;
  animation-name: curtainInner;
  animation-duration: 0s;
  animation-delay: .6s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

#body-wrap.on:after {
  animation-name: curtainStart,curtainEnd;
  animation-duration: .6s,.6s;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1), cubic-bezier(0.86, 0, 0.07, 1);
  animation-delay: 0s,.6s;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

img,
svg {
  width: 100%;
  height: auto;
}

.inner {
  position: relative;
  z-index: 1;
  padding: 0 15px;
}

.pc {
  display: none;
}

.smt {
  display: block;
}

ul {
  letter-spacing: -.4em;
}

ul li {
  letter-spacing: normal;
}

h1 {
  display: none !important;
}

#loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: #eee;
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 7;
  color: #9d9d9d;
  font-size: 16px;
  letter-spacing: 3px;
  transform: translate(-50%, -50%);
}

#produced {
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #9d9d9d;
  font-size: 13px;
  letter-spacing: 1px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 2s;
}

#produced.on {
  opacity: 1;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: auto;
  background-color: #fff;
}

header .header-in {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
}

#logo {
  width: 70px;
}

#logo a {
  display: block;
  line-height: 0;
}

#menu-btn,
#menu-btn span {
  cursor: pointer;
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}

#menu-btn {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 32px;
  height: 16px;
  z-index: 9;
  transform: translateY(-50%);
  transition: all .4s;
}

#menu-btn span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #000;
}

#menu-btn span:nth-of-type(1) {
  top: 0;
}

#menu-btn span:nth-of-type(2) {
  bottom: 0;
}

#menu-btn.active span:nth-of-type(1) {
  transform: translateY(6.5px) rotate(-315deg);
}

#menu-btn.active span:nth-of-type(2) {
  transform: translateY(-6.5px) rotate(315deg);
}

#nav-smt {
  overflow-y: scroll;
  position: fixed;
  top: -100%;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: #fcfbf5;
  box-sizing: border-box;
  transition: all .4s;
}

#nav-smt ul li {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

#nav-smt ul li a {
  display: block;
  position: relative;
  line-height: 1.6;
  padding: 15px;
}

#nav-smt ul li a small {
  display: block;
  color: #9d9d9d;
  font-size: 12px;
}

#nav-smt ul li a:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  width: 5px;
  height: 5px;
  border-top: 1px solid #bbb;
  border-right: 1px solid #bbb;
  transform: translateY(-50%) rotate(45deg);
}

#nav-smt ul li:last-of-type a {
  padding: 20px 15px;
  color: #fff;
  background: none;
  background-color: #D2001E;
}

#nav-smt ul li:last-of-type a small {
  color: #fff;
}

#nav-smt ul li:last-of-type a:after {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}

#nav-smt .nav-smt-foot {
  text-align: center;
  padding: 40px 0;
  color: #9d9d9d;
  background-color: #eee;
}

#nav-smt .nav-smt-foot .nav-logo {
  width: 80px;
  margin: 0 auto 10px;
}

#nav-smt .nav-smt-foot .schools {
  margin: 0 0 20px;
}

#nav-smt .nav-smt-foot .schools table {
  line-height: 1.8;
  text-align: left;
}

#nav-smt .nav-smt-foot .schools table tr th,
#nav-smt .nav-smt-foot .schools table tr td {
  padding: 5px;
}

#nav-smt .nav-smt-foot .schools table tr th {
  white-space: nowrap;
}

#nav-smt .nav-smt-foot .tel {
  line-height: 1.6;
}

#nav-smt .nav-smt-foot .tel strong {
  font-size: 28px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}

#nav-smt.show {
  top: 0;
}

#wrap {
  width: 100%;
  height: auto;
}

footer {
  text-align: center;
  color: #9d9d9d;
  background-color: #eee;
}

footer .footer-left {
  padding: 50px 0 0;
}

footer .footer-left #f-logo {
  width: 80px;
  margin: 0 auto 10px;
}

footer .footer-left .schools {
  display: inline-block;
}

footer .footer-left .schools table {
  line-height: 1.8;
  text-align: left;
}

footer .footer-left .schools table tr th,
footer .footer-left .schools table tr td {
  padding: 5px;
}

footer .footer-left .schools table tr th {
  white-space: nowrap;
}

footer .footer-right .tel {
  font-size: 16px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
  padding: 0 0 30px;
}

footer .footer-right .tel strong {
  font-size: 1.6em;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
}

footer .footer-right small.copy {
  display: block;
  padding: 5px;
}

/* ======================== lower */
#main-view {
  position: relative;
  margin: 65px 0 0;
}

#main-view span {
  display: block;
  line-height: 0;
}

#main-view h2 {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  z-index: 1;
  color: #fff;
  font-size: 20px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
  line-height: 1.8;
  letter-spacing: .1em;
  transform: translate(-50%, -50%);
}

#main-view h2 i {
  font-size: 1.1em;
  margin-right: 5px;
}

#main-view h2 small {
  display: block;
  text-align: center;
  font-size: 13px;
  font-family: "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  font-weight: normal;
}

#main-view:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

#content {
  padding: 8% 0 10%;
}

#content .lead {
  font-size: 14px;
}

#content .lead small {
  font-size: 13px;
}

#breadcrumb {
  display: none;
}

.pager {
  margin: 30px 0 0;
}

.pager.list {
  text-align: center;
}

.pager.list a,
.pager.list span {
  display: inline-block;
  line-height: 13px;
  padding: 14px 16px;
  margin: 5px;
  background-color: #fff;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow .2s;
}

.pager.list a:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

.pager.list span {
  color: #fff;
  background-color: #9d9d9d;
}

.pager.list div {
  display: inline-block;
}

.pager.single table {
  width: 100%;
}

.pager.single table tr td {
  text-align: center;
  box-sizing: border-box;
}

.pager.single table tr td a {
  display: block;
  padding: 15px 10px;
  background-color: #fff;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow .2s;
}

.pager.single table tr td a span {
  display: none;
}

.pager.single table tr td a:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

.pager.single table tr td.prev,
.pager.single table tr td.next {
  width: 25%;
}

.pager.single table tr td.toidx {
  width: 50%;
  padding: 0 10px;
}

.btns {
  text-align: center;
  margin: 5% 0 0;
}

.btns a,
.btns button {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  width: 100%;
  max-width: 300px;
  color: #fff;
  font-size: 15px;
  font-family: "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  padding: 15px;
  color: #fff;
  background-color: #D2001E;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
  transition: all .2s;
}

.btns a:hover,
.btns button:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

.btns .col2 {
  letter-spacing: -.4em;
  margin: 0 -2%;
}

.btns .col2 a,
.btns .col2 button {
  letter-spacing: normal;
  width: 46%;
  max-width: 240px;
  margin: 0 2%;
}

.btns a.gray,
.btns button.gray {
  background-color: #aaa;
}

.btns a.red,
.btns button.red {
  background-color: #D2001E;
}

#sns {
  margin: 30px 0 0;
}

#sns ul {
  text-align: center;
  line-height: 0;
}

#sns ul li {
  overflow: hidden;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 0 5px;
  border-radius: 50%;
}

#sns ul li a {
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
}

#sns ul li a span {
  display: table-cell;
  vertical-align: middle;
}

#sns ul li a img {
  width: 90%;
  position: relative;
  top: 3px;
}

#sns ul li a:hover {
  text-decoration: none;
}

#sns ul li:first-of-type {
  background-color: #3C5998;
}

#sns ul li:first-of-type:hover {
  background-color: #354e86;
}

#sns ul li:nth-of-type(2) {
  background-color: #1DA1F2;
}

#sns ul li:nth-of-type(2):hover {
  background-color: #0d95e8;
}

#sns ul li:nth-of-type(3) {
  background-color: #DD4B39;
}

#sns ul li:nth-of-type(3):hover {
  background-color: #d73925;
}

#sns ul li:nth-of-type(4) {
  background-color: #00c300;
}

@media screen and (min-width: 600px) {
  footer .footer-left #f-logo {
    width: 100px;
  }
  footer .footer-right .tel strong {
    font-size: 2em;
  }
  /* ======================== lower */
  #main-view h2 {
    font-size: 24px;
  }
  #content {
    padding: 6% 0 8%;
  }
  #content .lead {
    font-size: 15px;
  }
  .pager.single table tr td a {
    position: relative;
    padding: 15px;
  }
  .pager.single table tr td a span {
    display: inline;
  }
  .pager.single table tr td.next a:before,
  .pager.single table tr td.prev a:before {
    content: '';
    display: block;
    border: 4px solid transparent;
    position: absolute;
    top: 50%;
  }
  .pager.single table tr td.next a:before {
    right: 10px;
    border-left: 5px solid #9d9d9d;
    transform: translateY(-50%);
  }
  .pager.single table tr td.prev a:before {
    left: 10px;
    border-right: 5px solid #9d9d9d;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 800px) {
  .pc {
    display: block;
  }
  .smt {
    display: none;
  }
  .inner {
    padding: 0 30px;
  }
  header {
    width: 240px;
    height: 100%;
  }
  header .header-in {
    padding: 30px 0;
  }
  #logo {
    margin: auto;
    width: 60px;
  }
  #menu-btn {
    display: none !important;
  }
  #nav-pc {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #fff;
  }
  #nav-pc ul li {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  #nav-pc ul li a {
    display: block;
    position: relative;
    line-height: 1.6;
    padding: 10px 15px;
    background-color: #fff;
  }
  #nav-pc ul li a span {
    position: relative;
    z-index: 1;
  }
  #nav-pc ul li a span small {
    display: block;
    color: #9d9d9d;
    font-size: 12px;
  }
  #nav-pc ul li a:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    transition: width .2s;
  }
  #nav-pc ul li a:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    width: 5px;
    height: 5px;
    border-top: 1px solid #bbb;
    border-right: 1px solid #bbb;
    transform: translateY(-50%) rotate(45deg);
  }
  #nav-pc ul li a:hover:before {
    width: 100%;
  }
  #nav-pc ul li:last-of-type a {
    padding: 15px;
    color: #fff;
    background: none;
    background-color: #D2001E;
  }
  #nav-pc ul li:last-of-type a small {
    color: #fff;
  }
  #nav-pc ul li:last-of-type a:after {
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
  }
  #wrap {
    width: calc(100% - 240px);
    margin: 0 0 0 240px;
  }
  footer .footer-left {
    padding: 60px 0 0;
  }
  /* ======================== lower */
  #main-view {
    margin: 0;
  }
  #main-view h2 {
    font-size: 28px;
  }
  #breadcrumb {
    display: block;
    margin: 15px 30px 0;
  }
  #breadcrumb ol {
    overflow: hidden;
  }
  #breadcrumb ol li {
    float: left;
    font-size: 13px;
    margin: 0px 9px 0px 0px;
  }
  #breadcrumb ol li a {
    color: #019dd6;
    background: transparent url(../img/common/breadcrumb.png) no-repeat scroll right 5px;
    padding: 0px 13px 0px 0px;
  }
  #breadcrumb ol li a:hover {
    text-decoration: underline;
  }
  #breadcrumb ol li.categorize {
    background: transparent url(../img/common/breadcrumb.png) no-repeat scroll right 10px;
    padding: 0px 13px 0px 0px;
  }
  .pager {
    margin: 50px 0 0;
  }
  .pager.list {
    text-align: right;
  }
  #sns {
    margin: 50px 0 0;
  }
  #sns ul {
    text-align: left;
  }
}

@media screen and (min-width: 1000px) {
  /* ======================== lower */
  #main-view h2 {
    font-size: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .inner {
    padding: 0 50px;
  }
  #content {
    padding: 60px 0 80px;
  }
  #main-view h2 {
    font-size: 36px;
  }
  /*
	footer{
		position: relative;
		text-align: left;
		padding: 40px 0;
		.footer-left{
			padding: 0;
			#f-logo{
				display: inline-block;
				vertical-align: middle;
				margin: 0;
				padding: 5px 20px 5px 0;
				border-right: 1px solid #ccc;
				a{
					display: block;
					line-height: 0;
				}
			}
			.schools{
				display: inline-block;
				vertical-align: middle;
				padding: 0 0 0 10px;
				table{
					line-height: 1.8;
					text-align: left;
					tr{
						th,
						td{
							padding: 0;
						}
					}
				}
			}
		}
		.footer-right{
			position: absolute;
			bottom: 0;
			right: 30px;
			text-align: right;
			.tel{
				padding: 0;
				line-height: 1;
			}
			small.copy{
				padding: 0;
			}
		}
	}
*/
}
