
$baseColor: #D2001E;

$textColor: #333;
$fontSize: 13px;
$lineHeight: 2;


/* colors */
$red: #D2001E;

/* font-family */
$fontBase: 'Open Sans', YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
$fontJa: 'Noto Sans Japanese', serif;
$fontRobot: 'Roboto Condensed', sans-serif;




/* easing */
@mixin easeOutExpo_1s {
	transition: all 1s cubic-bezier(0.190, 1.000, 0.220, 1.000);
	transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
}
@mixin easeOutExpo_2s {
	transition: all 2s cubic-bezier(0.190, 1.000, 0.220, 1.000);
	transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

@mixin easeInExpo {
	transition: all .5s cubic-bezier(0.950, 0.050, 0.795, 0.035);
	transition-timing-function: cubic-bezier(0.950, 0.050, 0.795, 0.035);
}

@mixin easeOutBack {
	transition: all .5s cubic-bezier(0.175, 0.885, 0.305, 1.650);
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.305, 1.650);
}

@mixin easeInOutExpo {
	transition: all .5s cubic-bezier(1.000, 0.000, 0.000, 1.000);
	transition-timing-function: cubic-bezier(1.000, 0.000, 0.000, 1.000);
}