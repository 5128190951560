@charset "utf-8";

@import "variables";


html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,input,textarea,button,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;font-weight:inherit;font-style:normal;vertical-align:baseline;}
body{-webkit-text-size-adjust:100%;line-height:1;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
ul,ol{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:none}
a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;}
ins{background-color:#ff9;color:#000;text-decoration:none}
mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}
del{text-decoration:line-through}
abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
table{border-collapse:collapse;border-spacing:0}
input,select{vertical-align:middle}
a{color:inherit;text-decoration:none;outline:none;}
img{-ms-interpolation-mode: bicubic;}


::selection{background:#000;color:#fff;}
::-moz-selection{background:#000;color:#fff;}

*{
	box-sizing: border-box;
}

html{
	position: relative;
	overflow-x: hidden;
}
body{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: $fontSize;
	font-family: $fontBase;
	line-height: $lineHeight;
	letter-spacing: 0;
	color: $textColor;
	// background-color: #fff;
	background-color: #fcfbf5;
	// position: relative;
	overflow-x: hidden;
	// -webkit-text-stroke-width: 0.1px;
}

#body-wrap{
	overflow: hidden;
	position: relative;
	#body-inner{
		// line-height: 0;
		display: block;
		opacity: 0;
		position: relative;
		left: 100px;
		transition: left 1.5s;
	}
}
#body-wrap:after{
	content: '';
	opacity: 1;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
	width: 100%;
	height: 100%;
	background-color: #000;
	transform: translateX(-101%);
}
#body-wrap.on{
	#body-inner{
		left: 0;
		animation-name: curtainInner;
		animation-duration: 0s;
		animation-delay: .6s;
		animation-fill-mode: forwards;
		animation-iteration-count: 1;
	}
}
#body-wrap.on:after{
	animation-name: curtainStart,curtainEnd;
	animation-duration: .6s,.6s;
	animation-timing-function: cubic-bezier(.86,0,.07,1),cubic-bezier(.86,0,.07,1);
	animation-delay: 0s,.6s;
	animation-direction: alternate;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
}

img,
svg{
	width: 100%;
	height: auto;
}
.inner{
	position: relative;
	z-index: 1;
	// max-width: 1200px;
	// margin: 0 auto;
	padding: 0 15px;
}
.pc{display: none;}
.smt{display: block;}

ul{
	letter-spacing: -.4em;
	li{
		letter-spacing: normal;
	}
}

h1{
	display: none !important;
}

#loading{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 8;
	width: 100%;
	height: 100%;
	background-color: #eee;
	@include easeInOutExpo;
}
#loader{
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 7;
	color: #9d9d9d;
	font-size: 16px;
	letter-spacing: 3px;
	transform: translate(-50%, -50%);
}

#produced{
	white-space: nowrap;
	position: absolute;
	top: 50%;
	left: 50%;
	color: #9d9d9d;
	font-size: 13px;
	letter-spacing: 1px;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all 2s;
}
#produced.on{
	opacity: 1;
}



header{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9;
	width: 100%;
	height: auto;
	background-color: #fff;
	.header-in{
		position: relative;
		width: 100%;
		height: 100%;
		padding: 15px;
		box-sizing: border-box;
	}
}

#logo{
	width: 70px;
	a{
		display: block;
		line-height: 0;
	}
}

#menu-btn,
#menu-btn span {
	cursor: pointer;
	display: inline-block;
	transition: all .4s;
	box-sizing: border-box;
}
#menu-btn {
	position: absolute;
	top: 50%;
	right: 20px;
	width: 32px;
	height: 16px;
	z-index: 9;
	transform: translateY(-50%);
	transition: all .4s;
	span {
		position: absolute;
		left: 0;
		width: 100%;
		height: 3px;
		background-color: #000;
	}
	span:nth-of-type(1) {
		top: 0;
	}
	span:nth-of-type(2) {
		bottom: 0;
	}
}
#menu-btn.active{
	span:nth-of-type(1) {
		transform: translateY(6.5px) rotate(-315deg);
	}
	span:nth-of-type(2) {
		transform: translateY(-6.5px) rotate(315deg);
	}
}

#nav-smt{
	overflow-y: scroll;
	position: fixed;
	top: -100%;
	left: 0;
	z-index: 8;
	width: 100%;
	height: 100%;
	background-color: #fcfbf5;
	box-sizing: border-box;
	transition: all .4s;
	ul{
		li{
			border-top: 1px solid rgba(0,0,0,.05);
			a{
				display: block;
				position: relative;
				line-height: 1.6;
				padding: 15px;
				
				small{
					display: block;
					color: #9d9d9d;
					font-size: 12px;
				}
			}
			a:after{
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				right: 20px;
				width: 5px;
				height: 5px;
				border-top: 1px solid #bbb;
				border-right: 1px solid #bbb;
				transform: translateY(-50%) rotate(45deg);
			}
		}
		li:last-of-type{
			// margin: 10px 0 0;
			a{
				padding: 20px 15px;
				color: #fff;
				background: none;
				background-color: $red;
				small{
					color: #fff;
				}
			}
			a:after{
				border-top: 1px solid #fff;
				border-right: 1px solid #fff;
			}
		}
	}
	.nav-smt-foot{
		text-align: center;
		padding: 40px 0;
		color: #9d9d9d;
		background-color: #eee;
		.nav-logo{
			width: 80px;
			margin: 0 auto 10px;
		}
		.schools{
			margin: 0 0 20px;
			table{
				line-height: 1.8;
				text-align: left;
				tr{
					th,
					td{
						padding: 5px;
					}
					th{
						white-space: nowrap;	
					}
				}
			}
		}
		.tel{
			line-height: 1.6;
			// background: url(../svg/common/header/nav-off.svg) no-repeat center top;
			// background-size: auto auto;
			strong{
				// color: #000;
				font-size: 28px;
				font-family: $fontRobot;
				font-weight: 700;
			}
		}
	}
}
#nav-smt.show{
	top: 0;
}


#wrap{
	width: 100%;
	height: auto;
	// background-color: #fff;
}

footer{
	text-align: center;
	color: #9d9d9d;
	background-color: #eee;
	.footer-left{
		padding: 50px 0 0;
		#f-logo{
			width: 80px;
			margin: 0 auto 10px;
		}
		.schools{
			display: inline-block;
			table{
				line-height: 1.8;
				text-align: left;
				tr{
					th,
					td{
						padding: 5px;
					}
					th{
						white-space: nowrap;	
					}
				}
			}
		}
	}
	.footer-right{
		.tel{
			// color: #000;
			font-size: 16px;
			font-family: $fontJa;
			font-weight: 600;
			padding: 0 0 30px;
			strong{
				font-size: 1.6em;
				font-family: $fontRobot;
				font-weight: 700;
			}
		}
		small.copy{
			display: block;
			padding: 5px;
		}
	}
}


/* ======================== lower */
#main-view{
	position: relative;
	margin: 65px 0 0;
	span{
		display: block;
		line-height: 0;
	}
	h2{
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		z-index: 1;
		color: #fff;
		font-size: 20px;
		font-family: $fontJa;
		font-weight: 600;
		line-height: 1.8;
		letter-spacing: .1em;
		transform: translate(-50%, -50%);
		i{
			font-size: 1.1em;
			margin-right: 5px;
		}
		small{
			display: block;
			text-align: center;
			font-size: 13px;
			font-family: $fontBase;
			font-weight: normal;
		}
	}
}
#main-view:before{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.2);
}

#content{
	padding: 8% 0 10%;
	.lead{
		font-size: 14px;
		small{
			font-size: 13px;
		}
	}
}

#breadcrumb{
	display: none;
}

.pager{
	margin: 30px 0 0;
}
.pager.list{
	text-align: center;
	a,
	span{
		display: inline-block;
		line-height: 13px;
		padding: 14px 16px;
		margin: 5px;
		background-color: #fff;
		box-shadow: 4px 4px 0 rgba(0,0,0,0.2);
		transition: box-shadow .2s;
	}
	a:hover{
		// color: #fff;
		// background-color: $baseColor;
		box-shadow: 0 0 0 rgba(0,0,0,0.2);
	}
	span{
		color: #fff;
		background-color: #9d9d9d;
	}

	div{
		display: inline-block;
	}
}

.pager.single{
	table{
		width: 100%;
		tr{
			td{
				text-align: center;
				box-sizing: border-box;
				a{
					display: block;
					padding: 15px 10px;
					background-color: #fff;
					box-shadow: 4px 4px 0 rgba(0,0,0,0.2);
					transition: box-shadow .2s;
					span{
						display: none;
					}
				}
				a:hover{
					box-shadow: 0 0 0 rgba(0,0,0,0.2);
				}
			}
			td.prev,
			td.next{
				width: 25%;
			}
			td.toidx{
				width: 50%;
				padding: 0 10px;
			}
		}
	}
}
.btns{
	text-align: center;
	margin: 5% 0 0;
	a,
	button{
		cursor: pointer;
		text-align: center;
		display: inline-block;
		width: 100%;
		max-width: 300px;
		color: #fff;
		font-size: 15px;
		font-family: $fontBase;
		padding: 15px;
		color: #fff;
		background-color: $baseColor;
		box-shadow: 5px 5px 0 rgba(0,0,0,0.2);
		transition: all .2s;
	}
	a:hover,
	button:hover{
		box-shadow: 0 0 0 rgba(0,0,0,0.2);
	}
	.col2{
		letter-spacing: -.4em;
		margin: 0 -2%;
		a,
		button{
			letter-spacing: normal;
			width: 46%;
			max-width: 240px;
			margin: 0 2%;
		}
	}
	a.gray,
	button.gray{
		background-color:#aaa;
	}
	a.red,
	button.red{
		background-color: $red;
	}

}

#sns {
	margin: 30px 0 0;
	ul{
		text-align: center;
		line-height: 0;
		li{
			overflow: hidden;
			display: inline-block;
			width: 50px;
			height: 50px;
			margin: 0 5px;
			border-radius: 50%;
			a {
				display: table;
				width: 100%;
				height: 100%;
				text-align: center;
				color: #fff;
				box-sizing: border-box;
				span {
					display: table-cell;
					vertical-align: middle;
				}
				img{
					width: 90%;
					position: relative;
					top: 3px;
				}
			}
			a:hover {
				text-decoration: none;
			}
		}
		li:hover{
			// background-color: rgba(255,255,255,0.5);
		}
	// facebook
		li:first-of-type{
			background-color: #3C5998;
		}
		li:first-of-type:hover{
			background-color: darken(#3C5998, 5%);
		}
	// twitter
		li:nth-of-type(2){
			background-color: #1DA1F2;
		}
		li:nth-of-type(2):hover{
			background-color: darken(#1DA1F2, 5%);
		}
	// google+
		li:nth-of-type(3){
			background-color: #DD4B39;
		}
		li:nth-of-type(3):hover{
			background-color: darken(#DD4B39, 5%);
		}
	// LINE
		li:nth-of-type(4){
			background-color: #00c300;
		}
	}

}



@media screen and (min-width: 600px) {
	footer{
		.footer-left{
			#f-logo{
				width: 100px;
			}
		}
		.footer-right{
			.tel{
				strong{
					font-size: 2em;
				}
			}
		}
	}

/* ======================== lower */
	#main-view{
		h2{
			font-size: 24px;
		}
	}

	#content{
		padding: 6% 0 8%;
		.lead{
			font-size: 15px;
		}
	}

	.pager.single{
		table{
			tr{
				td{
					a{
						position: relative;
						padding: 15px;
						span{
							display: inline;
						}
					}
				}
				td.next,
				td.prev{
					a:before{
						content: '';
						display: block;
						border: 4px solid transparent;
						position: absolute;
						top: 50%;
					}
				}
				td.next{
					a:before{
						right: 10px;
						border-left: 5px solid #9d9d9d;
						transform: translateY(-50%);
					}
				}
				td.prev{
					a:before{
						left: 10px;
						border-right: 5px solid #9d9d9d;
						transform: translateY(-50%);
					}
				}
			}
		}
	}

}


@media screen and (min-width: 800px) {
	.pc{display: block;}
	.smt{display: none;}

	.inner{
		padding: 0 30px;
	}

	header{
		// text-align: center;
		width: 240px;
		height: 100%;
		// border-right: 1px solid #eee;
		// box-sizing: border-box;
		.header-in{
			padding: 30px 0;
		}
	}
	#logo{
		margin: auto;
		width: 60px;
	}
	#menu-btn {
		display: none !important;
	}
	#nav-pc{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: auto;
		background-color: #fff;
		ul{
			li{
				border-top: 1px solid rgba(0,0,0,.05);
				a{
					display: block;
					position: relative;
					line-height: 1.6;
					padding: 10px 15px;
					background-color: #fff;
					span{
						position: relative;
						z-index: 1;
						small{
							display: block;
							color: #9d9d9d;
							font-size: 12px;
						}
					}
				}
				a:before{
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 100%;
					background-color: rgba(0,0,0,.05);
					transition: width .2s;
				}
				a:after{
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					right: 20px;
					width: 5px;
					height: 5px;
					border-top: 1px solid #bbb;
					border-right: 1px solid #bbb;
					transform: translateY(-50%) rotate(45deg);
				}
				a:hover:before{
					width: 100%;
					
				}
			}
			li:last-of-type{
				a{
					padding: 15px;
					color: #fff;
					background: none;
					background-color: $red;
					small{
						color: #fff;
					}
				}
				a:after{
					border-top: 1px solid #fff;
					border-right: 1px solid #fff;
				}
			}
		}
	}

	#wrap{
		width: calc(100% - 240px);
		margin: 0 0 0 240px;
	}

	footer{
		.footer-left{
			padding: 60px 0 0;
		}
	}


/* ======================== lower */
	#main-view{
		// margin: 0 -15px 0 0;
		margin: 0;
		h2{
			font-size: 28px;
		}
	}

	#breadcrumb{
		display: block;
		margin: 15px 30px 0;
		// padding: 10px 15px 0;
		// max-width: 1200px;
		// margin: 0 auto;
		// box-sizing: border-box;
		ol{
			overflow: hidden;
			li{
				float: left;
				font-size: 13px;
				margin: 0px 9px 0px 0px;
				a{
					color: #019dd6;
    				background: transparent url(../img/common/breadcrumb.png) no-repeat scroll right 5px;
    				padding: 0px 13px 0px 0px;
				}
				a:hover{
					text-decoration: underline;
				}
			}
			li.categorize{
				background: transparent url(../img/common/breadcrumb.png) no-repeat scroll right 10px;
   				padding: 0px 13px 0px 0px;
			}
		}
	}

	.pager{
		margin: 50px 0 0;
	}
	.pager.list{
		text-align: right;
	}

	#sns {
		margin: 50px 0 0;
		ul{
			text-align: left;
		}
	}

}


@media screen and (min-width: 1000px) {

/* ======================== lower */
	#main-view{
		h2{
			font-size: 32px;
		}
	}

	

}


@media screen and (min-width: 1200px) {
	.inner{
		padding: 0 50px;
	}

	#content{
		padding: 60px 0 80px;
	}

	#main-view{
		h2{
			font-size: 36px;
		}
	}

	#breadcrumb{
		// margin: 15px 50px 0;
	}

/*
	footer{
		position: relative;
		text-align: left;
		padding: 40px 0;
		.footer-left{
			padding: 0;
			#f-logo{
				display: inline-block;
				vertical-align: middle;
				margin: 0;
				padding: 5px 20px 5px 0;
				border-right: 1px solid #ccc;
				a{
					display: block;
					line-height: 0;
				}
			}
			.schools{
				display: inline-block;
				vertical-align: middle;
				padding: 0 0 0 10px;
				table{
					line-height: 1.8;
					text-align: left;
					tr{
						th,
						td{
							padding: 0;
						}
					}
				}
			}
		}
		.footer-right{
			position: absolute;
			bottom: 0;
			right: 30px;
			text-align: right;
			.tel{
				padding: 0;
				line-height: 1;
			}
			small.copy{
				padding: 0;
			}
		}
	}
*/

}
